<!-- 模板 -->
<template>
  <div class="">
    <div class="contant">
      <headers class="top" :active="4"></headers>
      <div class="details">
        <div class="wu wuli">
          <div>
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
              <el-breadcrumb-item :to="{ path: 'call' }"
                >行业公益</el-breadcrumb-item
              >
              <el-breadcrumb-item>活动列表</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="bon">
            <img :src="$baseUrl + datas.image" alt="" class="bon_img" />
            <div class="bon_r">
              <div class="bon_one">
                <div class="tit">{{ datas.title }}</div>
                <div class="zhong">募捐中</div>
              </div>
              <div class="bon_two" v-html="datas.content"></div>
              <div style="display: flex" class="juan">
                <div class="proess">
                  <el-progress
                    :text-inside="true"
                    :stroke-width="20"
                    :percentage="datas.donation_percentage"
                    status="exception"
                  ></el-progress>
                </div>
                <div class="lss">
                  <el-input
                    v-model="money"
                    placeholder="请输入捐助金额"
                  ></el-input>
                </div>
              </div>
              <div class="price">
                <div class="price_l">
                  <div class="price_num">{{ datas.price }}</div>
                  <div class="price_tit">目标金额/元</div>
                </div>
                <div class="line"></div>
                <div class="price_l">
                  <div class="price_num">{{ datas.donation_money }}</div>
                  <div class="price_tit">已筹款/元</div>
                </div>
                <div class="line"></div>
                <div class="price_l">
                  <div class="price_num">{{ datas.donation_num }}</div>
                  <div class="price_tit">募捐次数/次</div>
                </div>

                <div class="ai" @click="juanzhu">爱心捐助</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-dialog
        title="爱心捐款"
        :visible.sync="dialogVisible"
        width="20%"
        :before-close="handleClose"
        center
      >
        <!-- <el-input v-model="input" placeholder="请输入内容"></el-input> -->
        <!-- <img
          src="../assets/image/9.png"
          alt=""
          style="width: 200px; margin: 0 auto"
          class="bon_img"
        /> -->

        <div
          class="qrcode bon_img"
          ref="qrCodeUrl"
          style="width: 200px; margin: 0 auto"
        ></div>
        <div class="wx">微信扫一扫</div>
      </el-dialog>
      <div class="describe">
        <div class="wu">
          <div class="des">
            <div class="programme">
              <div class="pro_po">
                <div class="ers">项目描述</div>
                <div class="xian"></div>
              </div>
              <div class="tits" v-html="datas.project_desc"></div>
              <!-- <div class="tits">
                近年来，新疆和硕不断加大野生动物保护宣传力度，群众保护野生动物意识明显提高，使一些因伤、因病落单需要救助的野生动物获得新生，重新回归大自然，构建了人与自然和谐相处的美好画卷。
              </div>
              <div class="bird_img">
                <img src="../assets/image/25.png" alt="" class="bird" />
              </div>

              <div class="tits">
                近日，地处大山深处的和硕县林业和草原局那音克管护站护林员接到牧民电话报警电话称，家门口发现一只白天鹅，由于翅膀受伤无法正常飞行，请管护站护林员进行施救。
                和硕县放牧牧民卡哈尔·肉斯里说：“我今天早上出门的时候，家门口突然出现了这个鸟，我也不知道这是属于什么类别的鸟，立即就给管护站的人打电话，让他们过来帮忙看看。”
              </div>
              <div class="bird_img">
                <img src="../assets/image/25.png" alt="" class="bird" />
              </div> -->
            </div>
            <div class="programme">
              <div class="pro_po">
                <div class="ers">项目预算</div>
                <div class="xian"></div>
              </div>
              <!-- <div class="ers">项目预算</div> -->
              <div class="tits" v-html="datas.project_budget">
                <!-- 食物5000份，单价50元，小计250000元<br />
                鸟棚100个，单价2000元，小计200000元
                <br />其他设施，小计20000元 -->
              </div>
            </div>
            <div class="programme">
              <div class="pro_po">
                <div class="ers">执行计划</div>
                <div class="xian"></div>
              </div>
              <!-- <div class="ers">执行计划</div> -->
              <div class="tits" v-html="datas.project_plan">
                <!-- 1.第一步第一步第一步第一步第一步第一步第一步<br />
                2.第二步第二步第二步第二步第二步第二步第二步第二步第二步<br />
                3.第三步第三步第三步第三步第三步第三步第三步第三步第三步第三步<br />
                4.第四步第四步第四步第四步第四步第四步第四步第四步 -->
              </div>
            </div>
            <div class="programme">
              <div class="pro_po">
                <div class="ers">募的款用途</div>
                <div class="xian"></div>
              </div>
              <!-- <div class="ers">募的款用途</div> -->
              <div class="tits" v-html="datas.project_money_use"></div>
            </div>
            <div class="programme">
              <div class="pro_po">
                <div class="ers">剩余财产使用方案</div>
                <div class="xian"></div>
              </div>
              <!-- <div class="ers">剩余财产使用方案</div> -->
              <div class="tits" v-html="datas.project_money_plan"></div>
            </div>
          </div>
        </div>
      </div>
      <footers></footers>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";

import headers from "../assembly/head.vue"; //头部
import request from "@/utils/request.js";
// import casesList from "../assembly/zizhi.vue"; //资质列表
import footers from "@/assembly/footer"; //页脚
// import qrcode from ""
// import sides from "@/assembly/side"; //侧边
export default {
  name: "",
  data() {
    return {
      dialogVisible: false,
      datas: "",
      money: "",
    };
  },
  props: [],
  components: {
    headers,
    // casesList,
    footers,
    // sides,
  },
  computed: {},
  filters: {},

  methods: {
    async juanzhu() {
      let options = {
        url: "/order/order/confirm",
        type: "post",
        data: {
          money: this.money,
          archives_id: this.$route.query.id,
        },
      };
      try {
        let res = await request(options);
       
        if (res.data.code !== 1) {
          return this.$message.error("请求数据失败");
        }
        this.dialogVisible = true;
        this.zhifu(res.data.data.order_sn);
      } catch (error) {
        this.$message.error("请求数据失败");
      }
    },
    async zhifu(e) {
      let options = {
        url: "/order/order/pay",
        type: "post",
        data: {
          order_sn: e,
          type: "wxpay_pc",
        },
      };
      try {
        let res = await request(options);
        console.log(res);
        if (res.data.code !== 1) {
          return this.$message.error("请求数据失败");
        }
        this.$refs.qrCodeUrl.innerHTML = ""
        this.creatQrCode(res.data.data.pay_code);
      } catch (error) {
        this.$message.error("请求数据失败");
      }
    },
    handleClose(done) {
      done();
    },
    creatQrCode(e) {
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: e,
        width: 200,
        height: 200,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
      console.log(qrcode);
    },
    async list() {
      let id = this.$route.query.id;
      console.log(id);
      let options = {
        url: "/index/detail",
        type: "post",
        data: {
          id,
        },
      };
      try {
        let res = await request(options);
        if (res.data.code !== 1) {
          return this.$message.error("请求数据失败");
        }
        this.datas = res.data.data;
        console.log(res.data.data);
      } catch (error) {
        console.log(error);
        this.$message.error("请求数据失败");
      }
    },
  },
  created() {
    this.list();
  },
  mounted() {
    let _this = this;

    // 页面渲染结束再执行动画
    this.$nextTick(function () {
      new _this.WOW({ live: false }).init();
    });
  },
};
</script>

<style lang="less" scoped>
//@import url(); 引入公共css类
/deep/.el-dialog--center .el-dialog__body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.wx {
  font-size: 16px;
  margin-top: 20px;
}
.contant {
  position: relative;
  .top {
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 10;
  }
  .details {
    min-height: 200px;
    background-color: #fff;
    .wuli {
      padding: 20px 0;
      .bon {
        margin-top: 60px;
        display: flex;
        .bon_img {
          width: 480px;
          margin-right: 20px;
        }
        .bon_r {
          .bon_one {
            display: flex;
            align-items: center;
            .tit {
              font-size: 24px;
              font-weight: bold;

              color: #252121;
              opacity: 1;
            }
            .zhong {
              width: 62px;
              height: 20px;
              background: #ee5b2d;
              opacity: 1;
              border-radius: 10px 10px 10px 0px;
              color: #fff;
              text-align: center;
              margin-left: 30px;
            }
          }
          .bon_two {
            font-size: 20px;
            font-weight: 400;
            line-height: 30px;
            color: #252121;
            opacity: 1;
            width: 90%;
            margin-top: 20px;
            margin-bottom: 20px;
          }
          .proess {
            width: 90%;
          }
          .price {
            display: flex;
            align-items: center;
            margin-top: 20px;

            .line {
              width: 1px;
              height: 40px;
              background-color: #e9e9e9;
              margin: 0 30px;
            }
            .price_l {
              text-align: center;
              .price_num {
                font-size: 20px;
                font-weight: bold;
                line-height: 34px;
                color: #252121;
              }
              .price_tit {
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                color: #252121;
              }
            }
            .ai {
              width: 140px;
              height: 40px;
              background: #ee5b2d;
              opacity: 1;
              border-radius: 20px;
              font-size: 20px;
              font-weight: bold;
              line-height: 40px;
              color: #ffffff;
              text-align: center;
              margin-left: 114px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .describe {
    // background-image: url("../assets/image/8.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 60px 0;
    background: #F7F8FA;
    .des {
      padding: 0px 60px;
      .programme {
        width: 100%;
        .ers {
          font-size: 30px;
          font-weight: bold;
          // line-height: 51px;
          color: #252121;
          opacity: 1;
          margin-top: 60px;
          z-index: 8;
        }
        .bird_img {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 20px;
          .bird {
            width: 664px;
            text-align: center;
          }
        }

        .tits {
          margin-top: 20px;
          font-size: 20px;
          font-weight: 400;
          line-height: 30px;
          color: #252121;
          opacity: 1;
          p {
            font-size: 20px;
            font-weight: 400;
            line-height: 30px;
            color: #252121;
            opacity: 1;
          }
        }
      }
    }
  }
}

.juan {
  align-items: center;
}
.pro_po {
  position: relative;
}
.xian {
  width: 120px;
  height: 8px;
  background: rgba(119, 99, 96, 1);
  opacity: 0.9;
  position: absolute;
  bottom: 0;
  z-index: 1;
  border-radius: 0 0 4px 4px;
}
.lss {
  margin-left: 15px;
}
</style>
<style>
::-webkit-scrollbar {
  width: 0;
  height: 0;
}
</style>
